<template>
  <div>
    <div class="_Tab">
      <span
        v-for="(item, index) in ['工作计划' /*'检查列表'*/]"
        :key="index"
        :class="{ activeColor: index == 0 }"
        @click="clickTabItem(index)"
      >
        {{ item }}
      </span>
      <div class="toPage">
        <img src="../../assets//images/toPage.png" alt="" />
        <span @click="$router.push('./QuestionPoint')">内外审检查</span>
      </div>
    </div>
    <el-button class="_ImgBtn" @click="toAdd"
      ><img src="../../assets/images/plan1.png" />新增计划</el-button
    >
    <div class="_TableBox" :style="{ height: elementHeight + 70 } + 'px'">
      <el-table
        :data="tableData.slice(start, end)"
        :height="elementHeight"
        style="width: 100%"
        :stripe="true"
        :row-class-name="tableRowClassName"
        :row-style="selectedstyle"
        @row-click="rowClick"
        @row-dblclick="rowDbClick"
      >
        <el-table-column prop="planNo" label="规划编号" width="180">
        </el-table-column>
        <el-table-column prop="planBeginDate" label="开始时间" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.planBeginDate | time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="planEndDate" label="结束时间">
          <template slot-scope="scope">
            <span>{{ scope.row.planEndDate | time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="checkName" label="负责组长"> </el-table-column>
        <el-table-column prop="saveName" label="创建人"> </el-table-column>
        <el-table-column label="操作" width="260">
          <template slot-scope="scope">
            <el-button
              icon="el-icon-delete"
              @click="deleteItem(scope.row, scope.$index)"
              style="margin-right: 10px"
              type="danger"
            ></el-button>
            <el-button
              icon="el-icon-edit"
              @click="editItem(scope.row)"
              style="margin-right: 10px"
              type="warning"
            ></el-button>
            <el-button
              v-if="!scope.row.approvalName"
              @click="approvePlan(scope.row)"
              type="primary"
              >批准计划</el-button
            >
            <el-button v-if="scope.row.approvalName" type="success"
              >批准通过</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="_Pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="curSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination>
    </div>
    <Dialog
      :showDialog="showDialog"
      :title="title"
      width="25%"
      top="15vh"
      @handleClose="handleClose"
      @handleReqData="handleReqData"
      class="dialog"
    >
      <el-form label-width="90px">
        <el-form-item label="所属公司：">
          <el-select
            v-model="company"
            placeholder="请选择"
            @change="changCompy"
          >
            <el-option
              v-for="item in cpmpyList"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年份：">
          <el-date-picker
            v-model="year"
            format="yyyy"
            value-format="yyyy"
            type="year"
            placeholder="选择年"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开始时间：">
          <el-date-picker
            v-model="planBeginDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：">
          <el-date-picker
            v-model="planEndDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :picker-options="{
              disabledDate: (time) =>
                time.getTime() <
                new Date(this.planBeginDate).getTime() /*- 8.64e7 */,
            }"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="负责组长：">
          <el-select v-model="checkNo" placeholder="请选择">
            <el-option
              v-for="item in personList"
              :key="item.userNo"
              :label="item.userName"
              :value="item.userNo"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类：">
          <el-select v-model="isProcess" placeholder="请选择">
            <el-option
              v-for="item in [
                { id: 0, name: '按部门' },
                { id: 1, name: '按过程' },
              ]"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标准：">
          <el-select v-model="standards" multiple placeholder="请选择">
            <el-option
              v-for="item in standardList"
              :key="item.value"
              :label="item.standardAbbr"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post, put } from "../../api/http";
import Dialog from "../../components/dialog/Dialog.vue";
export default {
  components: {
    Dialog,
  },
  data() {
    return {
      tableData: [],
      elementHeight: 0,
      showDialog: false,
      personList: [],
      cpmpyList: [],
      tableRowIndex: null,
      isAdd: true,
      dataItem: {},
      currentPage: 1,
      curSize: 10,
      start: 0,
      end: 10,
      //  弹窗参数
      planEndDate: "",
      planBeginDate: "",
      checkNo: "",
      company: "",
      year: new Date().getFullYear() + "",
      planNo: "",
      title: "",
      isProcess: 0,
      standards: [],
      standardList: [],
    };
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return "";
      }
      return new Date(e).toLocaleDateString();
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "filePage") {
      to.meta.ifDoFresh = true;
    }
    next();
  },

  activated() {
    if (this.$route.meta.ifDoFresh) {
      this.$route.meta.ifDoFresh = false;
      this.getElementHeight();
      this.getCompy();
      this.getDataList();
    }
  },
  mounted() {
    this.getElementHeight();
    this.getDataList();
    this.getStandard();
  },
  methods: {
    clickTabItem(i) {
      if (i == 1) this.$router.push("/filepage-ofno?planId=0");
    },
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 40 + 60 + 70); //70是div底部距离底部的高度
        console.log(this.elementHeight);
      });
    },
    getCompy() {
      get("/api/Company/GetAll").then((resp) => {
        if (resp.code == 200) {
          this.cpmpyList = resp.data;
        }
      });
    },
    getPlanNo() {
      get("/api/FileAuditPlan/GetPlanNo").then((resp) => {
        if (resp.code == 200) {
          this.planNo = resp.data;
        }
      });
    },
    getPerson() {
      get(
        "/api/User/GetByCompId?CompId=" + this.company + "&UserNo=&UserName="
      ).then((resp) => {
        if (resp.code == 200) {
          this.personList = resp.data;
        }
      });
    },
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },

    changCompy() {
      this.checkNo = "";
      this.getPerson();
    },
    handleClose() {
      this.showDialog = false;
    },
    getDataList() {
      get("/api/FileAuditPlan/GetList").then((resp) => {
        if (resp.code == 200) {
          this.tableData = resp.data;
        }
      });
    },
    getStandard() {
      get("/api/StandardInfo/GetList").then((resp) => {
        if (resp.code == 200) {
          this.standardList = resp.data;
        }
      });
    },
    toAdd() {
      this.title = "新增计划";
      this.isAdd = true;
      this.showDialog = true;
      this.company = "";
      this.planNo = "";
      this.year = "";
      this.planBeginDate = "";
      this.planEndDate = "";
      this.checkNo = "";
      this.isProcess = 0;
      this.standards = [];
      this.getPlanNo();
    },
    editItem(row) {
      this.title = "修改计划";
      this.showDialog = true;
      this.isAdd = false;
      this.company = row.compId;
      this.planNo = row.planNo;
      this.year = row.planYear + "";
      this.planBeginDate = row.planBeginDate;
      this.planEndDate = row.planEndDate;
      this.checkNo = row.checkUno;
      this.isProcess = row.isProcess;
      row.standardId.split(",").map((i) => {
        this.standards.push(i * 1);
      });
      this.getPerson();
    },
    handleReqData() {
      if (
        !this.checkNo ||
        !this.company ||
        !this.year ||
        !this.planBeginDate ||
        !this.planEndDate
      ) {
        this.$message({
          type: "warning",
          message: "所有选项不能为空",
        });
        return;
      }
      var company = this.cpmpyList.filter((i) => {
        return i.id == this.company;
      });
      var person = this.personList.filter((i) => {
        return i.userNo == this.checkNo;
      });
      var standard = [];
      this.standardList.map((e) => {
        this.standards.map((i) => {
          if (e.id == i) {
            standard.push(e.standardAbbr);
          }
        });
      });
      var user = this.$store.state.userInfo;
      var data = {
        id: this.isAdd ? 0 : this.dataItem.id,
        compId: this.company,
        compName: company[0].companyName,
        planNo: this.planNo,
        planYear: this.year,
        planQty: 0,
        planBeginDate: this.planBeginDate,
        planEndDate: this.planEndDate,
        approvalUno: "",
        approvalName: "",
        approvalDate: "2022-12-09T07:33:17.860Z",
        relationStandardNo: "string",
        saveUno: user.userNo,
        saveName: user.userName,
        saveDate: new Date().toISOString(),
        checkUno: person[0].userNo,
        checkName: person[0].userName,
        checkDate: "2022-12-09T07:33:17.860Z",
        chargeUno: user.userNo,
        chargeName: user.userName,
        isDelete: 0,
        isProcess: this.isProcess,
        tenantId: 0,
        standardId: this.standards.toString(),
        standard: standard.toString(),
        auditType: 0,
      };
      var methods = this.isAdd ? post : put;
      methods("/api/FileAuditPlan", data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: this.isAdd ? "新增成功" : "修改成功",
            });
            this.getDataList();
            this.showDialog = false;
          } else {
            this.$message({
              type: "error",
              message: resp.message,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: this.isAdd ? "新增失败" : "修改失败",
          });
        });
    },
    deleteItem(row, index) {
      this.$confirm("您确认要删除这个规划？")
        .then(() => {
          deleteMethod("/api/FileAuditPlan?Id=" + row.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.tableData.splice(index, 1);
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },

    rowClick(row) {
      this.tableRowIndex = row.index;
      this.dataItem = row;
    },
    rowDbClick(row) {
      row.planBeginDate = new Date(row.planBeginDate).toLocaleDateString();
      row.planEndDate = new Date(row.planEndDate).toLocaleDateString();
      if (row.approvalName) {
        this.$router.push({
          path: "/file-page?planId=" + row.id + "&time=" + row.planYear,
          query: {
            compId: row.compId,
            planBeginDate: row.planBeginDate,
            planEndDate: row.planEndDate,
            isProcess: row.isProcess,
          },
        });
      } else {
        this.$message("未批准计划不能跳转页面");
      }
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      if (rowIndex % 2 == 0) {
        return "";
      } else {
        return "warning-row";
      }
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          border: "5px solid",
          "background-color": "#E2E8FA !important",
        };
      }
    },
    approvePlan(e) {
      var user = this.$store.state.userInfo;
      if (e.saveUno == user.userNo) {
        put(
          "/api/FileAuditPlan/Approve?PlanId=" +
            e.id +
            "&ApprovalUno=" +
            user.userNo +
            "&ApprovalName=" +
            user.userName
        )
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "批准成功",
              });
              e.approvalName = user.userName;
            } else {
              this.$message.error("批准失败");
            }
          })
          .catch(() => {
            this.$message.error("批准失败");
          });
      } else {
        this.$message.warning("只能由创建人批准");
      }
    },
  },
};
</script>

<style lang="less" scoped>
._ImgBtn {
  background-color: #1361ff !important;
  color: white !important;
}
__label {
  padding: 0 !important;
}
._TableBox {
  margin-top: 10px;
}
.year {
  /deep/ & .el-input__inner {
    background: none !important;
  }
}
._Tab {
  color: #a09f9f;
  margin-bottom: 15px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

._Tab .activeColor {
  border-bottom: 2px solid #2f98ff;
  color: black;
}

._Tab span {
  padding: 10px 0;
}
.warning-row {
  background-color: red;
}
/deep/ .el-dialog .el-input {
  width: 180px !important;
}
/deep/ .el-select__tags {
  flex-wrap: nowrap !important;
  overflow: hidden;
}
.toPage {
  display: flex;
  align-items: flex-end;
  > img {
    width: 20px;
    height: 25px;
  }
  > span {
    color: #656565;
    border-bottom: 2px solid rgb(92, 92, 92);
    padding: 5px;
  }
}
</style>